import FormItem from 'antd/es/form/FormItem'
import Input from 'antd/es/input/Input'
import React from 'react'
import css from './index.module.scss'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { DatePicker, InputNumber, Select, Switch } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  NotFoundContentWithLoading,
  getOptionsClient,
  staticSelectProps,
  staticSelectPropsWithFilters
} from '../../atoms/Select'
import { PromocodeRoute } from '../../../api/types/enums'
import { promocodeBusinessMap } from '../../atoms/mappers/promocodeBusiness'
import { promocodeCurrencyOptionsMap } from '../../atoms/mappers/promocodeCurrencyOptions'
import { promocodeUsageOptionsMap } from '../../atoms/mappers/promocodeUsageOptions'
import { useOnSearchClients } from '../../../api/clients'
import { useWatch } from 'antd/es/form/Form'
import type dayjs from 'dayjs'
import type { FormValues } from './index.types'
import type { InputNumberProps } from 'antd'

const requiredProps = {
  rules: [{ required: true }]
}

export const numberParserNumberOfUses: InputNumberProps<number>['parser'] =
  value => Math.round(Number(value ?? 0))

// const regexp = /[^\d_a-zа-я]+/g

const REGEX = /^[A-ZА-Я\d-_]+$/

export const PromocodeNameField = () => (
  <FormItem
    label="Имя"
    name="name"
    rules={[
      { required: true },
      () => ({
        validator(_: any, value: string | undefined) {
          if (!value) {
            return Promise.resolve()
          }

          if (value.toUpperCase() !== value) {
            return Promise.reject(
              new Error('Промокод необходимо заполнять в верхнем регистре')
            )
          }

          if (!REGEX.test(value)) {
            return Promise.reject(new Error('Промокод не является валидным'))
          }

          return Promise.resolve()
        }
      })
    ]}
  >
    <Input
      allowClear
      className={css.fullWidth}
      placeholder="Введите уникальное имя для промокода"
    />
  </FormItem>
)

const clientFieldStaticProps = {
  label: 'Клиент',
  name: 'user'
}

export const UserField = () => {
  const { data, isLoading, onSearch, onClear } = useOnSearchClients({})

  return (
    <FormItem {...clientFieldStaticProps}>
      <Select
        {...staticSelectPropsWithFilters}
        allowClear={!isLoading}
        className={css.fullWidth}
        defaultActiveFirstOption={false}
        filterOption={false}
        loading={isLoading}
        notFoundContent={<NotFoundContentWithLoading loading={isLoading} />}
        onClear={onClear}
        onSearch={onSearch}
        options={getOptionsClient(data ?? [])}
        placeholder="Введите полный номер клиента для поиска"
      />
    </FormItem>
  )
}

export const StartDatetimeField = () => (
  <FormItem label="Дата начала действия" name="startDatetime">
    <DatePicker className={css.fullWidth} />
  </FormItem>
)

export const FinishDatetimeField = () => (
  <FormItem
    label="Дата окончания действия"
    name="finishDatetime"
    rules={[
      ({ getFieldValue }) => ({
        validator(_, finishDate: dayjs.Dayjs | undefined) {
          const startDate: dayjs.Dayjs | undefined =
            getFieldValue('startDatetime')
          if (!finishDate || !startDate) {
            return Promise.resolve()
          }
          if (
            finishDate.isBefore(startDate) ||
            startDate.diff(finishDate, 'days') === 0
          ) {
            return Promise.reject(
              new Error('Проверьте валидность дат периода действия промокода')
            )
          }
          return Promise.resolve()
        }
      })
    ]}
  >
    <DatePicker className={css.fullWidth} />
  </FormItem>
)

const numberParserDiscount: InputNumberProps<number>['parser'] = value =>
  Number(Number(value).toFixed(2))

export const DiscountField = () => {
  const form = useFormInstance<FormValues>()
  const discount = useWatch('discount', form)

  return (
    <FormItem {...requiredProps} label="Скидка" name="discount">
      <InputNumber<number>
        className={css.fullWidth}
        max={100}
        min={0}
        placeholder="Введите"
        prefix="%"
        step="0.01"
        {...(discount
          ? {
              parser: numberParserDiscount
            }
          : {})}
      />
    </FormItem>
  )
}

export const RouteField = () => {
  const entries = Object.entries(PromocodeRoute).map(([_, label]) => ({
    value: label,
    label
  }))

  const options = entries.filter(e => e.value !== PromocodeRoute.certificate)

  return (
    <FormItem {...requiredProps} label="Путь" name="route">
      <Select
        className={css.fullWidth}
        options={options}
        {...staticSelectProps}
        placeholder="Выберите путь промокода"
      />
    </FormItem>
  )
}

export const BusinessField = () => (
  <FormItem label="Бизнес" name="business">
    <Select
      className={css.fullWidth}
      options={Object.entries(promocodeBusinessMap).map(([key, label]) => ({
        value: key,
        label
      }))}
      {...staticSelectProps}
    />
  </FormItem>
)

export const IsActiveField = () => (
  <FormItem
    valuePropName="checked"
    {...requiredProps}
    label="Активен"
    name="isActive"
  >
    <Switch />
  </FormItem>
)

const isSpentableFieldTooltipProps = {
  title:
    "Флаг для изменения возможноcти применения одного и того же промокода несколькими людьми. В значении 'false' (ложь) — промокод после первого применения одним человеком станет недоступен для использования другими людьми",
  icon: <InfoCircleOutlined />
}

export const IsSpentableField = () => (
  <FormItem
    label="Можно переиспользовать другим людям"
    name="isSpentable"
    {...requiredProps}
    tooltip={isSpentableFieldTooltipProps}
    valuePropName="checked"
  >
    <Switch />
  </FormItem>
)

const minValueTooltipProps = {
  title: 'Минимальный размер пополнения, при котором промокод будет работать',
  icon: <InfoCircleOutlined />
}

export const MinValueField = () => (
  <FormItem
    {...requiredProps}
    label="Баллы от"
    name="minValue"
    tooltip={minValueTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={100}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

const maxValueTooltipProps = {
  title:
    'Если размер пополнения больше, чем это значение, то скидка будет рассчитываться не от всей суммы пополнения, а только от этого значения',
  icon: <InfoCircleOutlined />
}

export const MaxValueField = () => (
  <FormItem
    {...requiredProps}
    label="Баллы до"
    name="maxValue"
    tooltip={maxValueTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      max={2_000_000}
      min={0}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

const minPsychologistPriceTooltipProps = {
  title:
    'У клиента должна будет существовать хотя бы одна связь с психологом, у которого цена за индивидуальную сессию на русском не меньше этого значения включительно',
  icon: <InfoCircleOutlined />
}

export const MinPsychologistPriceField = () => (
  <FormItem
    label="Минимальная стоимость психолога от"
    name="minPsychologistPrice"
    tooltip={minPsychologistPriceTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={100}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

const maxPsychologistPriceTooltipProps = {
  title:
    'У клиента должна будет существовать хотя бы одна связь с психологом, у которого цена за индивидуальную сессию на русском не больше этого значения включительно',
  icon: <InfoCircleOutlined />
}

export const MaxPsychologistPriceField = () => (
  <FormItem
    label="Максимальная стоимость психолога до"
    name="maxPsychologistPrice"
    tooltip={maxPsychologistPriceTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={100}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

export const UsageOptionsField = () => (
  <FormItem {...requiredProps} label="Область применения" name="usageOptions">
    <Select
      className={css.fullWidth}
      options={Object.entries(promocodeUsageOptionsMap).map(([key, label]) => ({
        label,
        value: key
      }))}
      {...staticSelectProps}
    />
  </FormItem>
)

export const CurrencyField = () => (
  <FormItem {...requiredProps} label="Валюта" name="currency">
    <Select
      className={css.fullWidth}
      options={Object.entries(promocodeCurrencyOptionsMap).map(
        ([key, label]) => ({
          label,
          value: key
        })
      )}
      {...staticSelectProps}
    />
  </FormItem>
)

const quantityOfUsesTooltipProps = {
  title:
    'Сколько раз этот промокод может применить ОДИН человек. Если промокод "общественный" - каждый клиент сможет применить его столько раз. Если не заполнено, промокод можно применять бесконечно.',
  icon: <InfoCircleOutlined />
}

export const QuantityOfUsesField = () => (
  <FormItem
    label="Количество применений"
    name="quantityOfUses"
    tooltip={quantityOfUsesTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={0}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

const monthlyUsageTooltipProps = {
  title:
    'Сколько раз этот промокод может применить ОДИН пользователь за календарный месяц.',
  icon: <InfoCircleOutlined />
}

export const MonthlyUsageField = () => (
  <FormItem
    label="Ограничение в месяц"
    name="monthlyUsage"
    tooltip={monthlyUsageTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={0}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)

const overallQuantityOfUsesTooltipProps = {
  title: 'Сколько раз этот промокод может быть применен',
  icon: <InfoCircleOutlined />
}

export const OverallQuantityOfUsesField = () => (
  <FormItem
    label="Количество использований"
    name="overallQuantityOfUses"
    tooltip={overallQuantityOfUsesTooltipProps}
  >
    <InputNumber<number>
      className={css.fullWidth}
      min={0}
      placeholder="Введите"
      precision={0}
    />
  </FormItem>
)
